



















































































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { LibraryItem } from '@/interfaces/world/library';
import FirebaseGateway from '@/classes/firebaseGateway';
import { Getter } from 'vuex-class';

@Component
export default class Library extends Vue {
  private categories = [
    { value: 'rules', text: 'Regeln' },
    { value: 'world', text: 'Welt' },
    { value: 'misc', text: 'Sonstiges' },
    { value: 'personal', text: 'Persönlich' },
  ];

  private showCreateDialog = false;

  private uploading = false;

  private selectedFile: File | null = null;

  private uploadError: string | null = null;
  private error: string | null = null;

  private newLibraryItem: LibraryItem = {
    id: null,
    title: '',
    description: '',
    filename: '',
    thumbnailUrl: null,
    size: 0,
    category: '',
    uploadedBy: '',
  };

  private isLoading = true;

  private isDownloadingId: string | null = null;
  private showDeleteItemDialog = false;
  private itemToDelete: LibraryItem | null = null;
  private deletionInProgress = false;

  get items(): LibraryItem[] {
    return this.$store.getters.library;
  }

  get currentWorld() {
    return this.$store.getters.currentWorld;
  }

  get isWorldEditorOrAdmin(): string {
    return this.$store.getters.isWorldEditor(this.currentWorld);
  }
  @Getter
  private userId!: string;

  public mounted() {
    this.$store.dispatch('loadWorlds');
    this.$store.dispatch('loadLibrary').then(() => {
      this.isLoading = false;
    });
  }

  private getItemsByCategory(category: string) {
    return this.items
      .filter((libraryItem) => libraryItem.category === category)
      .sort((itemA, itemB) => (itemA.title < itemB.title ? -1 : 1));
  }

  private uploadLibraryItem() {
    if (this.selectedFile === null) {
      throw new Error('Cannot upload, no file selected');
    }

    this.uploading = true;
    this.newLibraryItem.filename = this.selectedFile.name;
    this.newLibraryItem.size = this.selectedFile.size;
    let uploadPath = '/worlds/' + this.currentWorld + '/library/';
    // If it is a personal library item, set the current userId
    if (this.newLibraryItem.category === 'personal') {
      this.newLibraryItem.uploadedBy = this.userId;
      this.newLibraryItem.isPersonal = true;
      uploadPath = '/user/' + this.userId + '/library/';
    }
    new FirebaseGateway()
      .uploadFile(this.selectedFile, uploadPath, true)
      .then((uploadTaskSnapshot) => {
        this.$store
          .dispatch('createLibraryItem', this.newLibraryItem)
          .then(() => {
            this.uploading = false;

            this.resetDialog();
          })
          .catch((error) => {
            this.uploading = false;
            this.uploadError = error;
          });
      })
      .catch((error) => {
        if (error instanceof Error && error.message === 'file_exists') {
          this.uploadError = 'Datei mit diesem Namen existiert bereits';
        } else {
          this.uploadError = error;
        }
        this.uploading = false;
      });
  }

  private downloadItem(libraryItem: LibraryItem) {
    this.error = null;
    this.isDownloadingId = libraryItem.id;
    this.$store
      .dispatch('loadLibraryItemUrl', libraryItem)
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.isDownloadingId = null;
      });
  }

  private resetDialog() {
    // Hide dialog
    this.showCreateDialog = false;

    // Reset item
    this.newLibraryItem = {
      id: null,
      title: '',
      description: '',
      filename: '',
      thumbnailUrl: null,
      size: 0,
      category: '',
      uploadedBy: '',
    };

    // Clear selected file
    this.selectedFile = null;
  }

  private deleteItem() {
    if (this.itemToDelete === null) {
      throw new Error('ItemToDelete is null');
    }
    this.deletionInProgress = true;
    this.$store.dispatch('deleteLibraryItem', this.itemToDelete).then(() => {
      this.deletionInProgress = false;
      this.closeDeleteDialog();
    });
  }

  private closeDeleteDialog() {
    this.showDeleteItemDialog = false;
    this.itemToDelete = null;
  }

  private onDeleteItemClick(item: LibraryItem) {
    this.itemToDelete = item;
    this.showDeleteItemDialog = true;
  }
}
